<template>
  <div class="wrapper new-version">
    <div class="content1">
      <el-form
        ref="form"
        :model="form"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="版本名称" prop="name">
          <el-input
            v-model="form.name"
            type="text"
            autocomplete="off"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item label="版本描述" prop="name">
          <el-input
            v-model="form.desc"
            type="textarea"
            autocomplete="off"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item label="权限类型" prop="name">
          <el-select v-model="form.type" placeholder="请选择活动区域">
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
            <!-- <el-option label="版本权限" value="1"></el-option>
            <el-option label="功能权限" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="市场端角色权限">
          <el-tree
            ref="tree1"
            :data="mis_data"
            show-checkbox
            node-key="id"
            :default-checked-keys="ckeckedKeys"
            :props="defaultProps"
            default-expand-all
          />
        </el-form-item>
        <el-form-item label="公司端角色权限">
          <el-tree
            ref="tree2"
            :data="company_menu"
            show-checkbox
            node-key="id"
            :default-checked-keys="ckeckedKeys"
            :props="defaultProps"
            default-expand-all
          />
        </el-form-item>
        <el-form-item label="市场助手端角色权限">
          <el-tree
            ref="tree3"
            :data="market_menu"
            show-checkbox
            node-key="id"
            :default-checked-keys="ckeckedKeys"
            :props="defaultProps"
            default-expand-all
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm">
            提交
          </el-button>
          <el-button plain @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import ctheader from '@@/ctheader';
import { apiPost, parse, isEmpty } from "../../modules/utils";
import APIs from "../../modules/APIs";
// import btns from '@@/btns';
// import router from '@/router';
import btns from "../../components/btns";
import ctheader from "../../components/ctheader";
import router from "../../router";
import * as URL from "../../modules/URLs";

export default {
  data() {
    return {
      mis_data: [],
      company_menu: [],
      market_menu: [],
      ckeckedKeys: [],

      defaultProps: {
        children: "children",
        label: "title",
      },

      form: {
        name: "",
        desc: "",
        type: 1,
      },
      typeList: [
        {
          id: 1,
          name: "版本权限",
        },
        {
          id: 2,
          name: "功能权限",
        },
      ],
      menuIds: [],
    };
  },

  mounted() {
    this.init();
    // this.getDetail();
  },
  methods: {
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].hidden ==1 && data[i].children.length < 1) {
          // 最后一级没有数据将children变成undefined
          // data[i] = []
          // data[i].children = undefined;
          data.splice(i, 1)
        } else {
          // children不为空时继续调用该方法
          this.getTreeData(data[i].children);

        }
      }
      return data;
    },
    init() {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "menu.list",
          genre: 0,
        },
        success(res) {
          if (res.code === "0") {
            console.log(res);
            _this.mis_data = res.data.mis_menu;
            // _this.mis_data =  _this.getTreeData(res.data.mis_menu)
            _this.company_menu = res.data.company_menu;
            _this.market_menu = res.data.market_menu;
            console.log(_this.mis_data);
          }
        },
        error(res) {},
      });
    },
    getDetail() {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "version.info",
          id: this.$route.params.id,
        },
        success(res) {
          if (res.code === "0") {
            // _this.form.id = res.data.id;
            // _this.form.name = res.data.name;
            // _this.form.desc = res.data.describe;
            // _this.form.type = res.data.type;
            // _this.ckeckedKeys = res.data.menu_ids;
            _this.form = {
              id: res.data.id,
              name: res.data.name,
              desc: res.data.describe,
              type: res.data.type,
            };
            setTimeout(() => {
              res.data.menu_ids.forEach((item) => {
                _this.$refs.tree1.setChecked(item, true, false);
                _this.$refs.tree2.setChecked(item, true, false);
                _this.$refs.tree3.setChecked(item, true, false);
              });
            }, 500);
          }
        },
        error(res) {},
      });
    },

    submitForm() {
      const parentArr1 = this.$refs.tree1.getHalfCheckedKeys();
      const childArr1 = this.$refs.tree1.getCheckedKeys();
      const ids1 = parentArr1.concat(childArr1);
      const parentArr2 = this.$refs.tree2.getHalfCheckedKeys();
      const childArr2 = this.$refs.tree2.getCheckedKeys();
      const ids2 = parentArr2.concat(childArr2);
      const parentArr3 = this.$refs.tree3.getHalfCheckedKeys();
      const childArr3 = this.$refs.tree3.getCheckedKeys();
      const ids3 = parentArr3.concat(childArr3);
      console.log(ids1.concat(ids2).concat(ids3));
      const ids = ids1.concat(ids2).concat(ids3);
      let _this = this;

      apiPost({
        url: APIs.VERSION,
        data: {
          action: "add.version",
          name: _this.form.name,
          desc: _this.form.desc,
          //   menu_ids: _this.$refs.tree.getCheckedKeys(),
          menu_ids: ids,
          id: _this.form.id,
          type: _this.form.type,
        },
        success(res) {
          if (res.code == "0") {
            router.push(URL.VERSION);
          } else {
            _this.$message.error(res.msg);
          }
        },
        error(res) {},
      });
    },

    getCheckedKeys(data, keys, key) {
      var res = [];
      recursion(data, false);
      return res;
      function recursion(arr, isChild) {
        var aCheck = [];
        for (var i = 0; i < arr.length; i++) {
          var obj = arr[i];
          aCheck[i] = false;
          if (obj.childMenus) {
            aCheck[i] = recursion(obj.childMenus, true) ? true : aCheck[i];
            if (aCheck[i]) {
              res.push(obj[key]);
            }
          }
          for (var j = 0; j < keys.length; j++) {
            if (obj[key] == keys[j]) {
              aCheck[i] = true;
              if (res.indexOf(obj[key]) == -1) {
                res.push(obj[key]);
              }
              break;
            }
          }
        }
        if (isChild) {
          return aCheck.indexOf(true) != -1;
        }
      }
    },

    goBack() {
      router.push(URL.VERSION);
    },
  },
};
</script>
<style lang="less">
@import url("../../less/common.less");
.content1 {
  margin-top: 20px;
  min-height: 100vh;
  background: #fff;
  box-shadow: 0 0 10px 0 #e0e3e8;
  border-radius: 4px;
  .el-button--primary {
    color: #fff;
    background-color: #257873;
    border-color: #257873;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #257873;
    border-color: #257873;
  }
  //   .el-button--warning:hover{
  //     background-color: #EF9E6F;
  //     border-color: #EF9E6F;
  //   }
  .el-button:hover {
    border-color: #257873;
    color: #606266;
  }
}
</style>
